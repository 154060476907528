import { Injectable, NgZone } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  SimpleSnackBar
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar, private zone: NgZone) {}

  showMessage(
    message: string,
    action = 'X',
    config?: MatSnackBarConfig
  ): MatSnackBarRef<SimpleSnackBar> {
    const matSnackBarConfig: MatSnackBarConfig = Object.assign(
      { duration: 4000 },
      config
    );
    return this.zone.run(() =>
      this.snackBar.open(message, action, matSnackBarConfig)
    );
  }

  showSuccess(
    message: string,
    action = 'X',
    config?: MatSnackBarConfig
  ): MatSnackBarRef<SimpleSnackBar> {
    const matSnackBarConfig: MatSnackBarConfig = Object.assign(
      {
        panelClass: ['success'],
        duration: 4000
      },
      config
    );
    return this.zone.run(() =>
      this.snackBar.open(message, action, matSnackBarConfig)
    );
  }

  showError(
    message: string,
    action = 'X',
    config?: MatSnackBarConfig
  ): MatSnackBarRef<SimpleSnackBar> {
    const matSnackBarConfig: MatSnackBarConfig = Object.assign(
      {
        panelClass: ['error'],
        duration: 4000
      },
      config
    );
    return this.zone.run(() =>
      this.snackBar.open(message, action, matSnackBarConfig)
    );
  }

  showWarning(
    message: string,
    action = 'X',
    config?: MatSnackBarConfig
  ): MatSnackBarRef<SimpleSnackBar> {
    const matSnackBarConfig: MatSnackBarConfig = Object.assign(
      {
        panelClass: ['warning'],
        duration: 4000
      },
      config
    );
    return this.zone.run(() =>
      this.snackBar.open(message, action, matSnackBarConfig)
    );
  }

  showInfo(
    message: string,
    action = 'X',
    config?: MatSnackBarConfig
  ): MatSnackBarRef<SimpleSnackBar> {
    const matSnackBarConfig: MatSnackBarConfig = Object.assign(
      {
        panelClass: ['info'],
        duration: 4000
      },
      config
    );
    return this.zone.run(() =>
      this.snackBar.open(message, action, matSnackBarConfig)
    );
  }
}
