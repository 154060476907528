import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  isloginVisible = false;

  titlePage = 'Consulte aqui seu saldo e extrato - Brasilprev';

  constructor(
    private meta: Meta,
    private title: Title,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.authService.headerEnabled.next(false);
    this.authService.isLoggedIn
      .pipe(take(1))
      .subscribe(
        (isLoggedIn: boolean) => isLoggedIn && this.router.navigate(['/home'])
      );
  }

  ngOnInit() {
    this.title.setTitle(this.titlePage);

    if (this.activatedRoute.snapshot.queryParams) {
      this.showLogin();
    }
    this.updateMetaDescription();
  }

  ngOnDestroy(): void {
    this.title.setTitle(environment.title);
    this.meta.removeTag('name="description"');
  }

  showLogin() {
    this.isloginVisible = true;
  }

  private updateMetaDescription(): void {
    let descriptionPage = 'Consulte aqui seu saldo, ';
    descriptionPage += 'extrato e outras funcionalidades. ';
    descriptionPage += 'Acesse com seu CPF e senha que você já usa ';
    descriptionPage += 'no site ou no app da Brasilprev.';

    this.meta.updateTag({
      name: 'description',
      content: descriptionPage
    });
  }
}
